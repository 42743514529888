
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { useRouter } from "vue-router";
import { Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import MotorBreakdown from "@/components/reusable/policy-information/documents-uploads/MotorBreakdown.vue";

export default defineComponent({
  name: "underwriter-policy-certificate-upload",
  components: { Field, MotorBreakdown },
  props: {
    redirect: String,
    policyRoute: String,
    policiesRoute: String,
    clientsRoute: String,
    role: String,
  },
  setup(props) {
    const router = useRouter();
    const submitFleetButton = ref<HTMLElement | null>(null);
    const vehicles = ref([]);
    const shortCode = ref("");
    const uploadType = ref("");
    const displayTable = ref(false);
    const displayFleet = ref(false);
    const defaultFieldClass = ref("col-md-4");
    const certificate = ref("");
    const clientPublicId = ref("");
    const policy = ref("");
    const publicId = ref("");
    const underwriteRole = variables.UNDERWRITER_USER_ROLE;
    const policies = ref([{ publicId: "", number: "" }]);
    const selectedPolicyId = ref("");

    const clients = ref([
      {
        fullName: "",
        publicId: "",
      },
    ]);
    const policySummary = ref({
      client: "",
      type: "",
      reference: "",
      year: "",
      number: "",
      publicId: "",
    });

    const defaults = () => {
      defaultFieldClass.value = "col-md-4";
      displayTable.value = false;
      displayFleet.value = false;
    };

    const getClients = () => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${props?.clientsRoute}`)
          .then(({ data }) => {
            clients.value = data.data;
          })
          .catch((error) => {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getClientPolicies = (event) => {
      defaults();

      const route =
        props?.role === variables.UNDERWRITER_USER_ROLE
          ? `${props?.policiesRoute}/${event.target.value}/policies`
          : `${props?.policiesRoute}/${event.target.value}/policies/MOT?withPolicyNumber=true`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${route}`)
          .then(({ data }) => {
            policies.value = data.data;
          })
          .catch((error) => {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getPolicy = (value) => {
      selectedPolicyId.value = value;
      defaults();

      const route =
        props?.role === underwriteRole
          ? `${props?.policyRoute}/${value}/details`
          : `${props?.policyRoute}/${value}`;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${route}`)
          .then(({ data }) => {
            policySummary.value = data.data.policySummary;
            vehicles.value = data.data.items;
            shortCode.value = data.data.policySummary.businessTypeShortCode;
            displayTable.value = true;
            publicId.value = policySummary.value.publicId;

            // if (props?.role === underwriteRole) {
            //   policySummary.value = data.data;
            //   vehicles.value = data.data.items;
            //   shortCode.value = data.data.businessTypeShortCode;
            // } else {
            //   policySummary.value = data.data.policySummary;
            //   vehicles.value = data.data.items;
            //   shortCode.value = data.data.policySummary.businessTypeShortCode;
            // }
          })
          .catch((error) => {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    };

    const getUploadType = (event: any) => {
      const value = event.target.value;
      if (value === "fleet") {
        defaultFieldClass.value = "col-md-6";
        displayFleet.value = true;
      } else {
        defaults();
      }
    };

    const documentSelected = (event) => {
      certificate.value = event.target.files[0];
    };

    const submitFleetUpload = () => {
      if (submitFleetButton.value) {
        // Activate indicator
        submitFleetButton.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitFleetButton.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            const fleetRoute =
              props?.role === underwriteRole
                ? `/underwriter/${publicId.value}/fleet-certificate`
                : `/policy/${publicId.value}/fleet-certificate`;

            const formData = new FormData();
            formData.append("uploadType", uploadType.value);
            formData.append("certificate", certificate.value as string | Blob);

            ApiService.setHeader();
            ApiService.post(fleetRoute, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: props.redirect,
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      // Get underwriters clients
      getClients();

      setCurrentPageBreadcrumbs("Upload Motor Certificate", [
        "Policies",
        "Policy Documentation",
      ]);
      MenuComponent.reinitialization();
    });

    return {
      getClientPolicies,
      getPolicy,
      getUploadType,
      documentSelected,
      submitFleetUpload,
      submitFleetButton,
      clients,
      policies,
      vehicles,
      policySummary,
      shortCode,
      uploadType,
      displayTable,
      displayFleet,
      defaultFieldClass,
      certificate,
      clientPublicId,
      policy,
      selectedPolicyId,
    };
  },
  created() {
    //Set page title
    document.title = "Policy Certificate Upload | " + process.env.VUE_APP_TITLE;
  },
});
